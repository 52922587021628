import React, { Component } from 'react'
import Link from 'gatsby-link'

import './blog.css'
import './pagination.css'

export default class Blog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pagination: {
        page: 1,
        perPage: 8
      }
    }
  }

  render () {
    const posts = this.props.posts
    const pagination = this.state.pagination || {}
    const paginated = paginate(posts, pagination)
    const pages = Math.ceil(posts.length / Math.max(
      isNaN(pagination.perPage) ? 1 : pagination.perPage, 1)
    )

    let prevBtn
    if (pagination.page > 1) {
      prevBtn = <button className='btn btn-blue newer-posts' onClick={this.changePage.bind(this, pagination.page - 1)}><span aria-hidden='true'>←</span> Newer Posts</button>
    }

    let nextBtn
    if (pagination.page < pages) {
      nextBtn = <button className='btn btn-blue older-posts' onClick={this.changePage.bind(this, pagination.page + 1)}>Older Posts <span aria-hidden='true'>→</span></button>
    }

    return (
      <div>
        {paginated.data.map(({ node: object }) =>
          <div key={object.id}>
            <small>{object.frontmatter.date}</small>
            <Link to={object.fields.slug}>
              <h2>{object.frontmatter.title}</h2>
            </Link>
            <p>{object.excerpt}</p>
            <Link to={object.fields.slug} className='read-more'>Continue reading <span aria-hidden='true'>→</span></Link>
            <hr />
          </div>
        )}
        <nav className='pagination'>
          {prevBtn}
          <span className='page-number'>Page {pagination.page} of {pages}</span>
          {nextBtn}
        </nav>
      </div>
    )
  }
  changePage (page) {
    const state = this.state
    const pagination = state.pagination || {}
    const pages = Math.ceil(this.props.posts.length / pagination.perPage)

    pagination.page = Math.min(Math.max(page, 1), pages)

    this.setState({
      pagination: pagination
    })
  }
};

// TODO: push to a package?
function paginate (data, o) {
  data = data || []

  // adapt to zero indexed logic
  var page = o.page - 1 || 0
  var perPage = o.perPage

  var amountOfPages = Math.ceil(data.length / perPage)
  var startPage = page < amountOfPages ? page : 0

  return {
    amount: amountOfPages,
    data: data.slice(startPage * perPage, startPage * perPage + perPage),
    page: startPage
  }
}
